.wrapper {
  display: flex;
  flex-direction: column;
	width: 100%;
	padding: 16px;
	background-color: var(--color-white);
  height: 100%;
  min-height: 287px;
}


.title {
  margin-bottom: 28px;
	font: var(--gilroy-Medium-24-28);
}

.tableHeading {
    display: grid;
    grid-template-columns: 6fr 1fr 1fr;
    gap: 16px;
}

.tableHeadingTitle {
  font: var(--gilroy-Medium-14);
  color: var(--color-grey)
}

.tableHeadingTitle:first-child  {
  padding-left: 33px;
}

.tableHeadingTitle:nth-child(2)  {
  min-width: 110px;
  text-align: left;
}

.tableHeadingTitle:last-child  {
  text-align: right;
}


.tableHeadingDetail {
    grid-template-columns: 6fr 2fr 1fr 1fr;
}

.countryElement {
  display: grid;
  grid-template-columns: 6fr 1fr 1fr;
  padding: 16px 0;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid var(--color-light-grey);
}

.countryElementDetail {
  grid-template-columns: 6fr 2fr 1fr 1fr;
}

.countryElement:last-child {
  border-bottom: none;
}


.countryPlace {
  display: inline-block;
  margin-right: 10px;
  padding-left: 8px;
  width: 22px;
  height: 24px;
  font: var(--gilroy-Medium-16-24);
	color: var(--color-grey)
}

.countryName {
  margin: 0;
	font: var(--gilroy-Medium-16-24);
  color: var(--color-dark-grey);
  line-height: 1.5;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.countryValue {
  min-width: 110px;
  text-align: left;
	font: var(--gilroy-Medium-16-24);
  color: var(--color-dark-grey);
}

.countryPercentage {
  text-align: right;
  font: var(--gilroy-Medium-16-24);
  color: var(--color-dark-grey);
}

.progress {
  width: fit-content;
  font: var(--gilroy-Medium-12-12);
}

.progressValue {
  font: var(--gilroy-Medium-14);
}

.progressValue svg {
  display: none;
  margin-right: 3px;
  margin-bottom: -1px;
}

.upStreams {
  padding: 2px;
  background-color: rgba(108, 148, 0, 0.09);
  border-radius: 2px;
  color: #6C9400;
}

.downStreams {
  padding: 2px;
  background-color: rgba(223, 78, 60, 0.09);
  border-radius: 2px;
  color: var(--color-red);
}

.upStreams svg {
  display: inline-block;
}

.downStreams svg {
  display: inline-block;
  transform: rotate(180deg);
}

.countryElementDetail .countryPercentage:nth-child(3) {
  text-align: left;
}


.countryElementDetail .countryPercentage:last-child {
  display: flex;
  justify-content: flex-end;
}


.normalPercent {
  color: var(--color-dark-grey);
  background-color: var(--color-light-grey);
}

.normalPercent svg {
  display: none;
}
 
.empty {
  display: flex;
  align-items: center;
  flex: 1;
}


@media (max-width: 768px) {
  .tableHeading {
    grid-template-columns: 3fr 2fr 2fr;
  }

  .countryElement {
    grid-template-columns: 3fr 2fr 2fr;
  }
}
