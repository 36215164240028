
.clickable_text {
  color: var(--color-dark-grey100);
  font: var(--gilroy-Medium-11);
  opacity: 1;
  width: 100px;
}

.chart_container svg rect:hover,
.chart_container svg .clickable_text:hover {
	cursor: pointer;
}

.chart_container .hint {
	background: var(--color-dark-grey);
	padding: 8px;
	color: var(--color-white);
	font: var(--gilroy-Medium-12);
	margin-bottom: 10px;
	transform: translate(-5px, -15px);
}

.chart_container .hintTotal {
  display: flex;
	flex-direction: column;
  min-width: 140px;
	padding: 6px;
  gap: 8px;

	background: var(--color-white);
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-14);
	transform: translate(0, 14px);
	box-shadow: 0px 1.85px 6.25px 0px #00000030, 0px 0.5px 1.75px 0px #0000000a;
}

.hintHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  font: var(--gilroy-Medium-11);
  color: var(--color-grey);
}

.hintContentHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  gap: 15px;
  font: var(--gilroy-Medium-12);
}

.hintContentHeading span:last-child {
  color: var(--color-grey);
  text-align: right;
}

.listGenders {
  padding: 0;
  margin: 0;  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  list-style: none;
}

.genderListElement {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 5px;
  font: var(--gilroy-Medium-12);
}

.genderListElement::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  flex-shrink: 0;
  transform: translateX(-5px);
}

.genderListElement:first-child::before {
  background-color:var(--color-light-green);
}

.genderListElement:last-child::before {
  background-color: var(--color-green);
}

.genderPercent {
  margin-left: auto;
  color: var(--color-grey);
  text-align: right;
}


