.tableElement {
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 2fr 2fr 2fr 2fr;
  gap: 15px;
  padding: 12px 0;
}

.tableElement:not(:last-child) {
  border-bottom: 1px solid var(--color-light-grey);
}

.infoWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 15vw;
}

.place {
  display: inline-block;
  flex-shrink: 0;
  width: 38px;
  margin-right: 10px;
  padding-left: 8px;
  font: var(--gilroy-Medium-16-24);
	color: var(--color-grey)
}

.cover {
  flex-shrink: 0;
  margin-right: 8px;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.cover img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.text {
  max-width: 13vw;
  font: var(--gilroy-Medium-16-24);
  line-height: 2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.title {
  cursor: pointer;
  transition: text-decoration 0.2s linear;
  color: var(--color-dark-grey);
}

.title:hover {
  text-decoration: underline;
}

.growth {
  display: flex;
  align-items: center;
  gap: 8px;
}

.countryPercentage {
  text-align: right;
  font: var(--gilroy-Medium-16-24);
  color: var(--color-dark-grey);
}

.progress {
  width: fit-content;
  font: var(--gilroy-Medium-12-12);
}

.progressValue {
  font: var(--gilroy-Medium-14);
}

.progressValue svg {
  display: none;
  margin-right: 3px;
  margin-bottom: -1px;
}

.upStreams {
  padding: 2px;
  background-color: rgba(108, 148, 0, 0.09);
  border-radius: 2px;
  color: #6C9400;
}

.downStreams {
  padding: 2px;
  background-color: rgba(223, 78, 60, 0.09);
  border-radius: 2px;
  color: var(--color-red);
}

.upStreams svg {
  display: inline-block;
}

.downStreams svg {
  display: inline-block;
  transform: rotate(180deg);
}

.countryPercentage:nth-child(3) {
  text-align: left;
}


.countryPercentage:last-child {
  display: flex;
  justify-content: flex-end;
}


.normalPercent {
  color: var(--color-dark-grey);
  background-color: var(--color-light-grey);
}

.normalPercent svg {
  display: none;
}
 
.empty {
  display: flex;
  align-items: center;
  flex: 1;
}
