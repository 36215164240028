.helper {
	position: relative;
	padding: 24px;
	margin-bottom: 24px;
	background: var(--color-light-green);
	font: var(--gilroy-Medium-16-140);
	letter-spacing: 1px;
}

.helper img {
	position: absolute;
	width: 16px;
	height: 16px;
	top: 8px;
	right: 8px;
	cursor: pointer;
}

.Helper {
	position: absolute;
	top: 55px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.map {
	overscroll-behavior: contain;
	pointer-events: none;
}

.map path:hover {
	fill: var(--color-map-accent-100);
	stroke: var(--color-map-stroke);
	stroke-width: 0.5px;
	cursor: pointer;
}

.map path:focus {
	fill: var(--color-map-accent-100);
	stroke: var(--color-map-stroke);
	stroke-width: 0.5px;
	outline: none;
}

.map path {
	pointer-events: all;
}

.territories__tooltip {
	padding: 8px 12px;
}

.territories__tooltip_country {
	font: var(--gilroy-Medium-14-24);
}

.territories__tooltip_title {
	font: var(--gilroy-Medium-12-15);
	color: rgba(0, 0, 0, 0.6);
}

.territories__tooltip_value {
	font: var(--gilroy-Bold-12-15);
	color: rgba(0, 0, 0, 0.6);
}

.territories__gradient {
	display: flex;
	justify-content: flex-end;
	font: var(--gilroy-Medium-12-15);
}

.territories__gradientId {
	padding: 5px;
}

.territories__gradientBox {
	align-self: center;
	width: 175px;
	height: 16px;
	background-image: linear-gradient(to right, var(--color-map-accent-15), var(--color-map-accent-100));
}

.territories__gradientContent {
	padding: 5px;
}

.territories__noData {
	z-index: 1;
	position: absolute;
	width: 200px;
	padding: 5px;
	padding-left: auto;
	padding-right: auto;
	top: 110px;
	left: calc(50% - 100px);
	font: var(--gilroy-Bold-24);
	color: var(---color-dark-grey100);
	background-color: rgba(255, 255, 255, 0.7);
	text-align: center;
}

.mainContent {
	position: relative;
  display: flex;
  min-height: 700px;
  flex-direction: column;
	justify-content: center;
  padding: 16px;
  background-color: var(--color-white);
}

.topTerritoriesChartTitle {
  margin-bottom: 12px;
	font: var(--gilroy-Medium-24-140p);
}


.topTen {
	display: flex;
	flex-direction: column;
	min-width: 210px;
	width: fit-content;
	flex-wrap: nowrap;
	margin: 30px;
	margin-bottom: 20px;
	padding: 12px;
	box-shadow: 0px 0.5px 1.75px rgba(0, 0, 0, 0.039),
		0px 1.85px 6.25px rgba(0, 0, 0, 0.19);
}

.topTenTitle {
	font: var(--gilroy-Bold-16-20);
	margin-bottom: 8px;
}

.topTenData li:not(:last-child) {
	margin-bottom: 8px;
}

.topTenData li {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	font: var(--gilroy-Medium-14-20);
	color: rgba(0, 0, 0, 0.6);
}

.reset {
	display: flex;
	justify-content: center;
}

.reset > button {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 2px;
	padding: 0;
	height: 35px;
	width: 35px;
	color: var(--color-white);
	border-radius: 100%;
	border: 0;
	background-color: var(--color-light-green);
}

.zoomBtnWrapper {
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.mapAndTopTen_wrapper {
	display: flex;
	justify-content: flex-start;
}

.mapWrapper {
	width: 100%;
  max-height: 600px;
}

.mapWrapper svg {
  width: 100%;
  height: 100%;
}
