.wrapper {
  display: flex;
  flex-direction: column;
	width: 100%;
	padding: 16px;
	background-color: var(--color-white);
  height: 100%;
  min-height: 303px;
}

.title {
  margin-bottom: 16px;
	font: var(--gilroy-Medium-24-28);
}

.graph {
	display: flex;
	align-items: center;
  flex: 1;
  column-gap: 44px;
  height: 100%;
}

.graph path {
  cursor: pointer;
}

.labels {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.labelListening,
.labelDownloads {
	background-color: var(--color-light-grey);
	display: flex;
	align-items: center;
	font: var(--gilroy-Medium-12);
	min-width: 173px;
}

.labelText {
	display: flex;
	justify-content: center;
	flex: 1;
	font: var(--gilroy-Medium-14);
	color: var(--color-grey);
}

.labelPercent {
	font: var(--gilroy-Bold-14-17);
}

.labelColorLine {
	width: 8px;
	height: 32px;
	background-color: green;
}

.textContent {
	display: flex;
	gap: 8px;
}

.leftSideLabel {
	display: flex;
	align-items: center;
	gap: 12px;
}

.label {
	display: flex;
	align-items: center;
	gap: 8px;
  padding-right: 10px;
	background-color: var(--color-light-grey);
	min-width: 173px;
}


.customClass {
  fill: green !important;
  stroke: green !important;
}

.tooltipGraphicWrapper {
  padding: 6px;
  width: 100%;
  min-width: 140px;
  background-color: var(--color-white);
  box-shadow: 0 1px 10px rgba(0,0,0,0.08);
}

.tooltipGraphic {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.tooltipGraphicOthers {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
}

.tooltipGraphicHeading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
  gap: 10px;
}

.tooltipGraphicOutlet {
  font: var(--gilroy-Medium-12);
  color: var(--color-dark-grey);
}

.tooltipGraphicOutletOthers{
  color: var(--color-grey);
}

.tooltipListOutletOthers {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
}

.tooltipElementOutletOthers {
  width: 100%;
  display: flex;
  justify-content: space-between;

}

.tooltipGraphicPercentage {
  font: var(--gilroy-Medium-12);
  color: var(--color-grey);
  text-align: right;
}


