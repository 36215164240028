.wrapper {
  display: flex;
  flex-direction: column;
	width: 100%;
	padding: 16px;
	background-color: var(--color-white);
  min-height: 491px;
}

.title {
  margin-bottom: 20px;
	font: var(--gilroy-Medium-24-28);
}

.tableHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}

.tableColumnTitle {
  font: var(--gilroy-Medium-14);
  color: var(--color-grey)
}

.tableColumnTitle:first-child {
  padding-left: 45px;
}