.wrapper {
  display: flex;
  flex-direction: column;
	padding: 16px;
	background-color: var(--color-white);
  flex: 50%;
  min-height: 287px;
}

.heading {
  position: relative;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.title {
	font: var(--gilroy-Medium-24-28);
}

.buttonInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: var(--color-grey);
}

.buttonInfo span {
  font: var(--gilroy-Medium-16-24);
  text-transform: capitalize;
  text-decoration: underline;
}

.wrapperInformer {
  position: absolute;
  top: 30px;
  right: 0;
  padding: 10px;
  min-width: 170px;
  background-color: var(--color-white);
  box-shadow: 0 1px 10px rgba(0,0,0,0.08);
}

.wrapperInformerHeading {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font: var(--gilroy-Medium-12);
  color: var(--color-grey);
}

.listSources {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
}

.source {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font: var(--gilroy-Medium-14);
}

.graph {
	display: flex;
	align-items: center;
  flex: 1;
  column-gap: 44px;
}

.graph svg g:first-child path:first-child {
  transform: translateY(0.6px) !important;
}

.tabs {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.outlet {
  padding: 6px 11px;
  font: var(--gilroy-Medium-14);
  color: var(--color-grey);
  border-bottom: 1px solid var(--color-light-grey);
  cursor: pointer;
}

.active {
  color: var(--color-dark-grey100);
  border-bottom: 1px solid var(--color-dark-grey100);
}

.disabled {
  opacity: .6;
  pointer-events: none;
}

.labels {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.labelListening,
.labelDownloads {
	background-color: var(--color-light-grey);
	display: flex;
  padding-right: 10px;
	align-items: center;
  gap: 5px;
	font: var(--gilroy-Medium-12);
	min-width: 173px;
}

.labelText {
	display: flex;
	justify-content: center;
	flex: 1;
	font: var(--gilroy-Medium-14);
	color: var(--color-grey);
}

.labelPercent {
	font: var(--gilroy-Bold-14-17);
}

.labelColorLine {
	width: 8px;
	height: 32px;
	background-color: green;
}

.textContent {
	display: flex;
	gap: 8px;
}

.leftSideLabel {
	display: flex;
	align-items: center;
	gap: 12px;
}

.graphPie g:last-child {
  transform: translate(40px, 10px) !important;
}


.streamsColor {
  background-color: var(--color-light-green);
  fill: var(--color-light-green);
}
