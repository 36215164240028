.wrapperArtists {
  display: flex;
  flex-direction: column;
  width: 100%;
	padding: 16px;
  min-height: 228px;
	background-color: var(--color-white);
  margin-bottom: 16px;
}

.heading {
  margin-bottom: 16px;
}

.title {
	font: var(--gilroy-Medium-24-28);
}

.listArtists {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0 20px;
}